import React from "react";
import SubjectList from "./guide-components/subject-list";
import Helmet from "react-helmet";
import SideNavbar from "./side-navbar";
import topicContent from "./content/all-topics";
import Layout from "../../templates/layout";
import "./styles/subject-page.scss";

const SubjectPage = (props) => {
  const singleSubject = props.pageContext.subject;

  return (
    <Layout>
      <div className="g-subject-page g-subject-page--layout">
        <Helmet
          title={`${singleSubject.subject} - VdoCipher Glossary`}
          meta={[
            {
              name: "description",
              content: `${singleSubject.metaDescription}`,
            },
            { property: "og:title", content: `${singleSubject.subject}` },
            { property: "og:type", content: "website" },
            { name: "twitter:card", content: "summary" },
            { name: "twitter:site", content: "@vdocipher" },
          ]}
        />
        <SideNavbar />
        <div className={`g-subject-page__main`}>
          <SubjectList
            bgColor="white"
            context="subject-page"
            singleSubject={singleSubject}
            topics={topicContent
              .filter(
                (topic) => topic.primarySubject === singleSubject.subjectId
              )
              .concat(
                topicContent.filter((topic) =>
                  topic.otherSubjects.includes(singleSubject.subjectId)
                )
              )}
          />
        </div>
      </div>
    </Layout>
  );
};

export default SubjectPage;
